
/* @import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;300;400;500;700;800;900&display=swap');

.App {
  text-align: center;
  background-color: #1d1d1b;
  /* height: 100vh; */
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* .suntory-logo {

  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #1D1D1B;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1D1D1B;
}

.animated-div {
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55); /* Adjust duration and timing function as needed */
}

.animated-div2 {
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55); /* Adjust duration and timing function as needed */
}

.change{
  transition: all 1s ease 500ms;
}

.changeOpacity{
  transition: all 1s ease 500ms;
}

.change2{
  transition: all 1.5s ease 500ms;
}
.App-link {
  color: #61dafb;
}

@keyframes play {
  from { top: 0; }
  to { top:  -49784px; }
}

.animation-container {
  position: absolute;
  width: 100%; /* Adjust width as needed */
  height: 49784px; /* Set to the height of your sprite sheet */
  /* background-image: url('./images/SmokePNG_12fps.png'); */
  animation: play 60s steps(48978) infinite; /* Adjust animation duration and steps */
    /* Hide overflow to clip the sprite sheet */
    overflow: hidden;
}




.gradient-custom {
  position: absolute;
  /* background-image: linear-gradient(to top, white, rgba(255,255,255,0)); */
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%);
  /* You can adjust the color stops and positions as needed */
}

.smokeEffect {
  position: absolute;
  
  background-size: cover;
  background-position: center;
}

/* @keyframes smokeAnimation {
  0% {
    transform: scaleX(0.95) scaleY(1);
    opacity: 0.7;
  }
  50% {
    transform: scaleX(1.1) scaleY(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(0.95) scaleY(1);
    opacity: 0.7;
  }
} */



/* @keyframes smokeAnimation {
  0% {
    transform: scale(1);
   
  }
  50% {
    transform: scale(1.3);
   
  }
  100% {
    transform: scale(1);
   
  }
} */




/* .animation1 {
  animation: smokeAnimation 10s infinite ease-in-out;
  animation-delay: 0s;
}

.animation2 {
  animation: smokeAnimation 12s infinite ease-in-out;
  animation-delay: 2s;
}

.animation3 {
  animation: smokeAnimation 14s infinite ease-in-out;
  animation-delay: 4s;
} */




@keyframes smokeAnimation {
  0% {
    transform: scale(1);

  }

 
  50% {
  
    opacity: 1;
  }
 
  100% {
    transform: scale(1.4); 
    opacity:0;
  }
}


.animation1 {
  animation: smokeAnimation 4s infinite ease-in;
  animation-delay: 0s;
 
}

.animation2 {
  animation: smokeAnimation 4s infinite  ease-in;
  animation-delay: 1s;
  
}

.animation3 {
  animation: smokeAnimation 4s infinite ease-in;
  animation-delay: 2s;
 
}


/* Preloader.css */
.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 200px;
  height: 200px;
  background-color: yellow;
  /* Add styles for loading spinner or progress bar */
}


/* .svg-image {
  width: 100%;
  height: auto;
  display: block;
} */

@media only screen and (min-device-pixel-ratio: 2), 
       only screen and (min-resolution: 192dpi) {
  .svg-image {
    image-rendering: -webkit-optimize-contrast; /* Safari */
    /* image-rendering: crisp-edges; */
    /* image-rendering: pixelated; */
  }
}


